<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('sm_commission')"
    :primary-button-text="$t('got_it')"
    size="small"
    hide-close-button
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('update:modelValue', false)"
  >
    <div class="text-body-2 flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <span>{{ $t('commission_popup_description') }}</span>
        <div class="flex flex-col gap-4 rounded-xl bg-primary-10 px-3 py-1.5">
          <h4>
            {{
              useMoneyFormatter(
                Number(deal.commissions?.find((c) => c.type?.code === FINANCE_COMMISSION_TYPES.SM)?.amount)
              )
            }}
          </h4>
          <span>SM Commission = (BC - CA - BK - PC - PMC ) * SMC (%)</span>
        </div>
      </div>
      <div>
        <ul class="text-body-2 list-disc px-6 text-left">
          <li v-for="commission in commissions" :key="commission.code">
            {{ commission.text }} ({{
              useMoneyFormatter(Number(deal.commissions?.find((c) => c.type?.code === commission.code)?.amount) || 0)
            }})
          </li>
        </ul>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Deal } from '@/types'
import { FINANCE_COMMISSION_TYPES } from '~/constants'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  deal: Deal
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const commissions = ref([
  {
    code: FINANCE_COMMISSION_TYPES.BROKERAGE,
    text: 'BC = Brokerage Commission',
  },
  {
    code: FINANCE_COMMISSION_TYPES.COMMISSION_ADJUSTMENT,
    text: 'CA = Commission Adjustment',
  },
  {
    code: FINANCE_COMMISSION_TYPES.KICKBACK_PAYMENT,
    text: 'BK = Buyer Kickback',
  },
  {
    code: FINANCE_COMMISSION_TYPES.PARTNER,
    text: 'PC = Partner Commission',
  },
  {
    code: FINANCE_COMMISSION_TYPES.PARTNER_MANAGER,
    text: 'PMC = Partner Manager Commission',
  },
])
</script>

<style scoped></style>
